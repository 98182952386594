import { gql } from '@apollo/client';

export const GET_NOTIFICATION_LOGS = gql`
  query notificationLogs(
    $filter: NotificationLogFilter!
    $where: NotificationLogWhereFilter
  ) {
    notificationLogs(filter: $filter, where: $where) {
      count
      data {
        id
        createdAt
        receiverUsers {
          id
          firstName
          lastName
        }
        senderUser {
          id
          firstName
          lastName
        }
        templateKey
        status
        notificationType
        subject
        serviceResponse
      }
    }
  }
`;

export const GET_NOTIFICATION_LOG = gql`
  query notificationLog($where: NotificationLogWhereInput!) {
    notificationLog(where: $where) {
      id
      templateKey
      notificationType
      createdAt
      senderId
      status
      receiverUsers {
        id
        firstName
        lastName
      }
      senderUser {
        id
        firstName
        lastName
      }
      subject
      serviceResponse
      provider
    }
  }
`;

export const GET_LOGS_FILTER = gql`
  query notificationLogFilters($filter: NotificationLogFilter!) {
    notificationLogFilters(filter: $filter) {
      count
      data {
        id
        templateKey
        notificationType
        createdAt
        subject
        serviceResponse
        provider
        status
      }
    }
  }
`;

export const TENANT_FILTER = gql`
  query tenantFilters($filter: TenantFilter!) {
    tenantFilters(filter: $filter) {
      count
      data {
        name
        subDomain
        phoneNo
        email
        isActive
      }
    }
  }
`;

export const SPONSORS_FILTER = gql`
  query sponsors($filter: SponsorFilter!) {
    sponsors(filter: $filter) {
      count
      data {
        id
        businessName
      }
    }
  }
`;
