import { EyeOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Checkbox, Divider, Dropdown, Empty, message, Tag } from 'antd';
import dayjs from 'dayjs';
import {
  capitalize,
  debounce,
  filter,
  forEach,
  isEmpty,
  map,
  startCase,
} from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import FilterSelectedIcon from '../../../assets/svg/filter-selected.svg';
import FilterIcon from '../../../assets/svg/filter.svg';
import api from '../../../common/api';
import {
  DEFAULT_DATE_FORMAT,
  importExportOptions,
  ROUTES,
  SKIP_RECORD,
} from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import RangePickerComponent from '../../../components/RangePickerComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import {
  GET_ACTIVITY_LOGS,
  GET_ACTIVITY_LOGS_FILTER,
} from '../graphql/Queries';

const initialPaginationValue = {
  total: 0,
  current: 1,
};

let scrollDebounce = null;

const ActivityLogTable = () => {
  const {
    state: { pageSize, filterData, currentUser },
    dispatch,
  } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  const initialLogsFilter = useMemo(
    () => ({
      skip: 0,
      limit: pageSize,
      sortOn: 'createdAt',
      sortBy: 'DESC',
    }),
    [pageSize],
  );

  const [filters, setFilters] = useState(filterData);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [activityLogFilter, setActivityLogFilter] = useState(initialLogsFilter);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const handleViewLog = (id) => {
    const otherFilters = { activityLogFilter, paginationProp, filters };
    const stringifyObject = JSON?.stringify(otherFilters);

    navigate(`${ROUTES?.ACTIVITY_LOGS}/view/${id}`, {
      state: {
        stringifyObject,
      },
    });
  };

  const [activityLogs, { loading, data }] = useLazyQuery(GET_ACTIVITY_LOGS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.activityLogs?.count,
      };
      setPaginationProp(pagination);
    },
  });

  const [activityLogFilters] = useLazyQuery(GET_ACTIVITY_LOGS_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.activityLogFilters?.data, (item) => {
          optionsCopy?.push(item?.[filterIndex]);
        });
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.activityLogFilters?.data, (item) => {
          optionsCopy?.push(item?.[filterIndex]);
        });
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.activityLogFilters?.data?.length < SKIP_RECORD);

      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  useEffect(() => {
    activityLogs({
      variables: {
        filter: activityLogFilter,
        ...(filterData && { where: filterData }),
      },
    });
    setFilters(filterData);
    dispatch({ type: 'SET_FILTER_DATA', data: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getFilterData = (confirm) => {
    activityLogs({
      variables: {
        filter: { ...activityLogFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState }),
      },
    });
    setFilters(filtersCopyState);
    setActivityLogFilter({
      ...activityLogFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        default:
          activityLogFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex,
              },
            },
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          default:
            activityLogFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex,
                },
              },
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const handleReset = (clearFilters, dataIndex) => {
    let filtersCopy = {
      ...filters,
    };
    if (dataIndex === 'createdAt') {
      delete filtersCopy?.createdAt;
    } else {
      filtersCopy = {
        ...filters,
        [dataIndex]: [],
      };
    }
    setFilters(filtersCopy);
    activityLogs({
      variables: {
        filter: {
          ...activityLogFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC',
        },
        ...(filtersCopy && { where: filtersCopy }),
      },
    });
    setActivityLogFilter({
      ...activityLogFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' },
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value,
        ),
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value],
      };
    }

    setFiltersCopyState(filtersCopy);
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      default:
        activityLogFilters({
          variables: {
            filter: {
              sortOn: filterIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: filterIndex,
            },
          },
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value,
      ),
    };
    setFiltersCopyState(filtersCopy);
  };
  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: dayjs(values?.[0])?.startOf('day'),
      to: dayjs(values?.[1])?.endOf('day'),
    };

    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete filtersCopy?.[dataIndex];
    }
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown range-filter-dropdown">
            <RangePickerComponent
              format={DEFAULT_DATE_FORMAT}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                filtersCopyState?.[dataIndex]?.from
                  ? [
                      dayjs(filtersCopyState?.[dataIndex]?.from, 'DD/MM/YYYY'),
                      dayjs(filtersCopyState?.[dataIndex]?.to, 'DD/MM/YYYY'),
                    ]
                  : []
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="common-button discard-button filter-button"
                id="date-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button filter-button"
                id="date-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            <SearchComponent
              className="list-search-box filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              getData={(value) => handleSearch(value, dataIndex)}
            />
            {filtersCopyState?.[dataIndex]?.length > 0 && (
              <div className="filter-section">
                {map(filtersCopyState?.[dataIndex], (item) => (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span
                      title={
                        filterIndex === 'refType'
                          ? capitalize(startCase(item?.label)) ||
                            capitalize(startCase(item?.toString()))
                          : item?.label || item?.toString()
                      }
                    >
                      {filterIndex === 'refType'
                        ? capitalize(startCase(item?.label)) ||
                          capitalize(startCase(item?.toString()))
                        : item?.label || item?.toString()}
                    </span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {filterList?.length > 0 ? (
                map(filterList, (item) => (
                  <div className="single-checkbox-div" key={item?.key || item}>
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item,
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="common-checkbox"
                    >
                      <span
                        title={
                          filterIndex === 'refType'
                            ? capitalize(startCase(item?.label)) ||
                              capitalize(startCase(item?.toString()))
                            : item?.label || item?.toString()
                        }
                      >
                        {filterIndex === 'refType'
                          ? capitalize(startCase(item?.label)) ||
                            capitalize(startCase(item?.toString()))
                          : item?.label || item?.toString()}
                      </span>
                    </Checkbox>
                  </div>
                ))
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="common-button discard-button filter-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button filter-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <img src={FilterSelectedIcon} alt="filter-icon" width={16} />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
      if (visible) {
        setFiltersCopyState(filters);
      }
    },
  });

  const columns = [
    {
      title: 'Entity',
      key: 'refType',
      dataIndex: 'refType',
      width: 130,
      className: 'max-width-column',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'refType' && sortedInfo?.order,
      ...filterPopup('refType'),
      render: (refType) => capitalize(startCase(refType)) || '-',
    },
    {
      title: 'USER',
      ellipsis: true,
      width: 130,
      dataIndex: 'userName',
      key: 'userName',
      sorter: true,
      className: 'max-width-column',
      ...filterPopup('userName'),
      sortOrder: sortedInfo?.columnKey === 'userName' && sortedInfo?.order,
      render: (_, record) =>
        record?.user?.firstName?.length > 0
          ? `${record?.user?.firstName} ${record?.user?.lastName || ''}`
          : '-',
    },
    {
      title: 'METHOD',
      key: 'httpMethod',
      dataIndex: 'httpMethod',
      className: 'max-width-column',
      width: 120,
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'httpMethod' && sortedInfo?.order,
      ...filterPopup('httpMethod'),
      render: (httpMethod) => httpMethod || '-',
    },
    {
      title: 'ACTIVITY TYPE',
      key: 'activityType',
      align: 'center',
      className: 'max-width-column',
      dataIndex: 'activityType',
      width: 150,
      ...filterPopup('activityType'),
      render: (activityType) => activityType || '-',
    },
    {
      title: 'DEVICE',
      key: 'deviceType',
      align: 'center',
      className: 'max-width-column',
      dataIndex: 'deviceType',
      width: 120,
      render: (deviceType) => deviceType || '-',
    },
    {
      title: 'OS',
      key: 'userAgent',
      align: 'center',
      className: 'max-width-column',
      dataIndex: 'userAgent',
      width: 120,
      render: (userAgent) =>
        `${userAgent?.os?.name} (${userAgent?.os?.version})` || '-',
    },
    {
      title: 'BROWSER',
      key: 'userAgent',
      align: 'center',
      className: 'max-width-column',
      dataIndex: 'userAgent',
      width: 120,
      render: (userAgent) =>
        `${userAgent?.browser?.name} (${userAgent?.browser?.version})` || '-',
    },
    {
      title: 'IP ADDRESS',
      key: 'ip',
      dataIndex: 'ip',
      className: 'max-width-column',
      width: 150,
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'ip' && sortedInfo?.order,
      render: (ip) => ip || '-',
    },
    {
      title: 'DATE & TIME',
      key: 'createdAt',
      dataIndex: 'createdAt',
      className: 'max-width-column',
      width: 160,
      fixed: 'right',
      sorter: true,
      ...filterPopup('createdAt'),
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (createdAt) => (
        <span>
          {createdAt ? dayjs(createdAt)?.format(DEFAULT_DATE_FORMAT) : '-'}
        </span>
      ),
    },
    {
      dataIndex: 'id',
      align: 'right',
      width: 50,
      fixed: 'right',
      render: (id) => <EyeOutlined onClick={() => handleViewLog(id)} />,
    },
  ];

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    },
  };

  const handleExport = (e) => {
    const { key } = e;
    if (key === 'SELECTED' && !selectedKeys?.length) {
      message?.destroy();
      message?.error('Please select records!');
      return;
    }
    setExportLoading(true);
    api({
      method: 'POST',
      url: `${process.env.REACT_APP_SERVER_REST_URL}/export`,
      data: {
        module: 'ACTIVITY_LOG',
        userId: currentUser?.id,
        filtersObj: {
          ...filters,
          ...(key === 'SELECTED' && { id: selectedKeys }),
        },
      },
    })
      .then((res) => {
        if (res?.data?.message) {
          message?.info(res.data.message);
        }
        setExportLoading(false);
      })
      .catch((error) => {
        message?.error(error?.response?.data?.error || 'got some problem');
        setExportLoading(false);
      });
  };

  const handleTableChange = (pagination, _, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setActivityLogFilter({ ...activityLogFilter, skip });
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setActivityLogFilter({
        ...activityLogFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field === 'tenant' ? 'name' : sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      activityLogs({
        variables: {
          filter: {
            ...activityLogFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field === 'tenant' ? 'name' : sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    } else {
      setActivityLogFilter({
        ...activityLogFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      activityLogs({
        variables: {
          filter: {
            ...activityLogFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const onSearchChange = (value) => {
    setActivityLogFilter({
      ...activityLogFilter,
      skip: value
        ? 0
        : activityLogFilter?.limit * (paginationProp?.current - 1),
      search: value,
    });
    activityLogs({
      variables: {
        filter: {
          ...activityLogFilter,
          skip: value
            ? 0
            : activityLogFilter?.limit * (paginationProp?.current - 1),
          search: value,
        },
        ...(filters && { where: filters }),
      },
    });
  };

  return (
    <div className="activity-log-wrapper">
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <SearchComponent
            id="search-container-id"
            placeholder="logs or other detail.."
            name="LineOfBusiness"
            getData={onSearchChange}
          />
        </div>
        <div className="activity-log-header">
          <Dropdown
            menu={{
              items: importExportOptions,
              selectable: true,
              onClick: handleExport,
              className: 'export-btn-dropdown',
            }}
            placement="bottom"
          >
            <Button
              className="common-button export-button activity-export-btn"
              id="user-table-export-btn"
              loading={exportLoading}
              type="primary"
            >
              Export
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="common-table">
        {pageSize && (
          <TableComponent
            loadingData={loading}
            columns={columns}
            data={data?.activityLogs?.data || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
            rowSelection={rowSelection}
          />
        )}
      </div>
    </div>
  );
};
export default ActivityLogTable;
