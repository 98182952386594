import { Col, Row, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import ShowMoreComponent from '../../../components/ShowMoreComponent';
import '../notificationLogsModule.less';

const NotificationLogDetail = ({ notificationData }) => {
  const receiverUsersName = useMemo(
    () =>
      notificationData?.receiverUsers?.map((item) => ({
        label:
          notificationData?.senderUser?.firstName?.length > 0
            ? `${item?.firstName} ${item?.lastName}`
            : '-',
        id: item?.id,
      })) || [],
    [notificationData],
  );

  return (
    <div className="notification-log-wrapper">
      <div className="content-section notification-log-details">
        <Row gutter={20}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">TimeStamp :</span>
              <span className="field-value">
                {dayjs(notificationData?.createdAt)?.format('YYYY-MM-DD') ||
                  '-'}
              </span>
            </div>
          </Col>

          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Notification Type :</span>
              <span className="field-value">
                {notificationData?.notificationType || '-'}
              </span>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Email Subject :</span>
              <span className="field-value">
                {notificationData?.subject || '-'}
              </span>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Created At :</span>
              <span className="field-value">
                {dayjs(notificationData?.createdAt)?.format('YYYY-MM-DD') ||
                  '-'}
              </span>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Status :</span>
              <span className="field-value">
                {notificationData?.status || '-'}
              </span>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Sender :</span>
              <span className="field-value">
                {notificationData?.senderUser?.firstName?.length > 0 ? (
                  <Tag
                    className="single-item"
                    title={
                      notificationData?.senderUser?.firstName?.length > 0
                        ? `${notificationData?.senderUser?.firstName} ${notificationData?.senderUser?.lastName}`
                        : '-'
                    }
                  >
                    <span className="content-text">
                      {`${notificationData?.senderUser?.firstName} ${notificationData?.senderUser?.lastName}`}
                    </span>
                  </Tag>
                ) : (
                  '-'
                )}
              </span>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <div className="field-detail">
              <span className="field-label">Recipient :</span>
              <span className="field-value">
                {notificationData?.receiverUsers?.length > 0 ? (
                  <ShowMoreComponent show={4} data={receiverUsersName} />
                ) : (
                  '-'
                )}
              </span>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <div className="field-detail">
              <span className="field-label">Request :</span>
              <pre className="params-info">
                {JSON?.stringify(
                  notificationData?.serviceResponse,
                  undefined,
                  2,
                ) || '-'}
              </pre>
            </div>
          </Col>
        </Row>

        {notificationData?.error && (
          <Row gutter={16}>
            <Col span={24}>
              <div className="field-detail">
                <span className="field-label">Error :</span>
                <span className="params-info">
                  {JSON?.stringify(notificationData?.error?.message) || '-'}
                </span>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default NotificationLogDetail;
